import { RouteComponentProps, useLocation } from "@gatsbyjs/reach-router";
import { CircularProgress, IconButton } from "@mui/material";
import { APImage, APRow, APColumn, APSizedBox, APText, APCard, APIcon, APPalette, getIt, APCenter, APButton, APExpanded, APTag, showSnackbar, APScrollView, APAsyncButton } from "ap-components";
import { Type } from "class-transformer";
import { useEffect, useState } from "react";
import Slider, { Settings as SliderSettings } from 'react-slick';
import CMS2Services from "../../Services/CMS2Services";
import moment from "moment";
import { useWindowDimensions } from "ap-components/src/hooks/useWindowDimension";
import showAlertSnackBarConfirmation from "ap-components/src/dialogs/showAPAlertSnackBar";
import MutualFundApiServices from "../../Services/MutualFundServices";

export const PARTNER_URL = import.meta.env.REACT_APP_PARTNER_URL ?? "";

export default function EventDialog(props: RouteComponentProps, { onClick }: { onClick: () => void }) {
    const [event, setEvent] = useState<EventModel | null>();


    let location = useLocation();
    const { width: windowWidth } = useWindowDimensions();

    async function getEventFunction() {
        var res = await getIt(CMS2Services).getEvent(location.search.split("event_id=")[1]);
        setEvent(res[0])
    }


    useEffect(() => {
        getEventFunction();
    }, [])


    if (!event) {
        return <APCenter><CircularProgress /></APCenter>
    }


    const dt = new Date(event?.eventDate);
    // // var { width } = useWindowDimensions();
    function NextIcon(props: any) {

        return (
            <IconButton onClick={props.onClick} style={{ position: "absolute", top: "-10%", right: 0, transform: "translate(50%,-50%)" }}>
                {/* <ChevronRight className="carousel-cta" style={{ color: APPalette.black, backgroundColor: APPalette["grey-100"], opacity: 0.5, borderRadius: "50%", fontSize: "48px" }} /> */}
                <APImage src="/img/icon-chevron-right.png" width={20} height={20} />
            </IconButton>
        )
    }

    function PrevIcon(props: any) {

        return (
            <IconButton onClick={props.onClick} style={{ position: "absolute", top: "-10%", left: '450px', zIndex: 10, transform: "translate(-50%,-50%)" }}>
                {/* <ChevronLeft className="carousel-cta" style={{ color: APPalette.black, backgroundColor: APPalette["grey-100"], opacity: 0.5, borderRadius: "50%", fontSize: "48px" }} /> */}
                <APImage src="/img/icon-chevron-left.png" width={20} height={20} />
            </IconButton>
        )
    }

    const sliderSettings: SliderSettings = {
        className: "slider variable-width",
        slidesToShow: 2.5,
        slidesToScroll: 3,
        variableWidth: false,
        infinite: false,
        centerMode: false,
        nextArrow: <NextIcon />,
        prevArrow: <PrevIcon />,

    };
    if (windowWidth && windowWidth <= 600) {

        return (
            <APColumn crossAxisAlignment="center" mainAxisAlignment="center" mainAxisSize="max">

                <APCard style={{ maxWidth: '600px', minHeight: '400px' }}>
                    <APColumn crossAxisAlignment="stretch" mainAxisSize="max">

                        <APScrollView>
                            <APColumn crossAxisAlignment="start">
                                <APText variant="h2">

                                    {event.title ?? ''}
                                </APText>

                            </APColumn>
                            <APSizedBox height="16px" />
                            <APColumn style={{ padding: "8px", maxWidth: '600px' }} crossAxisAlignment="start">
                                <APExpanded flex={2}>
                                    <APColumn crossAxisAlignment='start'>

                                        <APImage
                                            fit
                                            width={'100%'}
                                            height={'243px'}
                                            style={{ backgroundColor: APPalette['brand-100'], borderRadius: '8px' }}
                                            src={event.poster?.url ?? "/img/default_poster.png"}
                                        />                            {event.description && <>
                                            <APSizedBox height="16px" />
                                            <APText variant="h3">About Event</APText>
                                            <APText variant='paragraph-medium'>{event.description}</APText>
                                        </>}
                                        {
                                            event.members !== undefined && event?.members?.length > 0 && <>
                                                <APSizedBox height="16px" />
                                                <APText variant="h3">Speaker Details</APText>
                                                <APSizedBox height="4px" /></>
                                        }
                                        {event.members !== undefined && event?.members?.length <= 2 &&
                                            <APRow mainAxisSize="min">
                                                {event.members.map((member, index) => {
                                                    return <APRow key={index} style={{ width: '210px' }}>
                                                        <SpeakerDetailsCard title={member.title} name={member.name} designation={member.designation ?? ''} />
                                                        {
                                                            index < event.members!.length && <APSizedBox width="8px" />
                                                        }
                                                    </APRow>
                                                })}
                                            </APRow>
                                        }
                                        {/* {
                                event.members !== undefined && event.members!.length > 2 &&
                                <APRow >
                                    <APExpanded>
                                        <Slider lazyLoad="anticipated"  {...sliderSettings} >
                                            {event.members.map((member, index) => {
                                                return <APRow key={index} style={{ width: '210px' }}>
                                                    <SpeakerDetailsCard title={member.title} name={member.name} designation={member.designation ?? '-'} />
                                                    <APSizedBox width="16px" />
                                                </APRow>
                                            })}
                                        </Slider>
                                    </APExpanded>
                                </APRow>
                            } */}

                                        {/* {
                                event.attatchments && event.attatchments.length > 0 &&
                                <>
                                    <APSizedBox height="16px" />
                                    <APText variant="h3">Attachments</APText>
                                    <APSizedBox height="8px" />
                                    <APRow >
                                        {
                                            event.attatchments.map((attachment, index) => {
                                                return <AttachmentRow key={index} type={attachment.type} label={attachment.label} url={attachment.attatchment.url} />
                                            })
                                        }
                                    </APRow>
                                </>

                            }
                            {
                                event.links && event.links.length > 0 &&
                                <>
                                    <APSizedBox height="8px" />
                                    <APRow style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }} mainAxisSize="min" >
                                        {
                                            event.links.map((link, index) => {
                                                return <LinkRow key={index} title={link.title} link={link.link} />
                                            })
                                        }
                                    </APRow>
                                </>

                            } */}

                                    </APColumn>
                                </APExpanded>
                                <APSizedBox height={"16px"} />
                                <APColumn crossAxisAlignment="start" style={{ width: "272px" }}>
                                    <APExpanded>
                                        <APCard style={{ width: "272px" }}>
                                            <APRow crossAxisAlignment="center">
                                                <APIcon icon={event.isOnline ? "video" : "location"} size={16} color={APPalette["brand-300"]} />
                                                <APSizedBox width="4px" />
                                                <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={2}>
                                                    {event.isOnline ? "Zoom Event" : event.location}
                                                </APText>
                                            </APRow>
                                            <APSizedBox height="8px" />
                                            <APRow crossAxisAlignment="center">
                                                <APIcon icon={"calendar"} size={16} color={APPalette["brand-300"]} />
                                                <APSizedBox width="4px" />
                                                <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={1}>
                                                    {moment(event.eventDate).format('DD MMM YYYY, hh:mm A, ddd')}
                                                </APText>
                                            </APRow>
                                            <APSizedBox height="8px" />
                                            <APRow crossAxisAlignment="center">
                                                <APIcon icon={"language02"} size={16} color={APPalette["brand-300"]} />
                                                <APSizedBox width="4px" />
                                                <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={1}>
                                                    {(event.languages)}
                                                </APText>
                                            </APRow>
                                            <APSizedBox height="8px" />
                                            <APRow>
                                                {
                                                    event.isOnline && event.isLive() ?

                                                        <APExpanded>
                                                            <APAsyncButton size="small"

                                                                onClick={async () => {
                                                                    window.open(event.meetingLink);

                                                                }}
                                                                type="positive"
                                                                leading={<APIcon icon={"video"}
                                                                    size={16} color={APPalette.white} />}>
                                                                Join Now
                                                            </APAsyncButton>
                                                        </APExpanded> :

                                                        <APExpanded>
                                                            <APButton size="small"
                                                                onClick={() => {
                                                                    window.open(PARTNER_URL + "/home/resources/events?register=true&event_id=" + event._id);
                                                                    // window.open("https://partner.assetplus.in/home/resources/events?register=true&event_id=" + event._id);
                                                                }}
                                                            >
                                                                {"Register"}
                                                            </APButton>
                                                        </APExpanded>
                                                }

                                                <APSizedBox width="16px" />
                                                <APCard style={{ padding: '12px', borderRadius: '8px', maxHeight: '38px', cursor: 'pointer' }} onClick={async () => {
                                                    var link = await getIt(MutualFundApiServices).getDynamicLink({ eventId: location.search.split("event_id=")[1] });
                                                    navigator.clipboard.writeText(link);
                                                    showAlertSnackBarConfirmation({ content: "Link to the event is copied!", intent: "positive" })
                                                }}>
                                                    <APIcon icon={"share02"} size={16} color={APPalette["grey-700"]} />
                                                </APCard>
                                            </APRow>
                                        </APCard>
                                    </APExpanded>


                                    {!event.isOnline && event.locationLink != null && <>
                                        <APText variant="h3">Venue</APText>
                                        <APText variant='paragraph-small'>{event.location}</APText>
                                        <APSizedBox height="8px" />
                                        <APButton onClick={() => window.open(event.locationLink)}
                                            leading={<APIcon icon={'indicator'} size={16} color={APPalette["brand-300"]} />}
                                            type='tertiary'>
                                            Show on Map
                                        </APButton>
                                    </>}
                                    <APSizedBox height="16px" />
                                    <APText variant="h3">Category</APText>
                                    <APSizedBox height="8px" />
                                    <APTag variant="info" size="small">
                                        {formatLabel(event.eventCategory)}
                                    </APTag>
                                </APColumn>
                            </APColumn>
                        </APScrollView>


                    </APColumn>
                </APCard>

            </APColumn>
        )
    }
    if (moment().diff(moment(event.eventDate), "hours") > 0 && !event.isLive()) {
        return <EmptyState label={"Event not found"} description="Sorry, this event is not available right now" />
    }
    return (
        <APColumn crossAxisAlignment="center" mainAxisAlignment="center" mainAxisSize="max">
            <APCard style={{ width: '880px' }}>
                <APColumn crossAxisAlignment="start">
                    <APText variant="h2">

                        {event.title ?? ''}
                    </APText>

                </APColumn>
                <APSizedBox height="16px" />
                <APRow style={{ padding: "8px", width: '850px' }} crossAxisAlignment="start">
                    <APExpanded flex={2}>
                        <APColumn crossAxisAlignment='start'>

                            <APImage
                                fit
                                width={'100%'}
                                height={'243px'}
                                style={{ backgroundColor: APPalette['brand-100'], borderRadius: '8px' }}
                                src={event.poster?.url ?? "/img/default_poster.png"}
                            />                            {event.description && <>
                                <APSizedBox height="16px" />
                                <APText variant="h3">About Event</APText>
                                <APText variant='paragraph-medium'>{event.description}</APText>
                            </>}
                            {
                                event.members != undefined && event?.members?.length > 0 && <>
                                    <APSizedBox height="16px" />
                                    <APText variant="h3">Speaker Details</APText>
                                    <APSizedBox height="4px" /></>
                            }
                            {event.members != undefined && event?.members?.length <= 2 &&
                                <APRow mainAxisSize="min">
                                    {event.members.map((member, index) => {
                                        return <APRow key={index} style={{ width: '210px' }}>
                                            <SpeakerDetailsCard title={member.title} name={member.name} designation={member.designation ?? ''} />
                                            {
                                                index < event.members!.length && <APSizedBox width="8px" />
                                            }
                                        </APRow>
                                    })}
                                </APRow>
                            }
                            {
                                event.members != undefined && event.members!.length > 2 &&
                                <APRow >
                                    <APExpanded>
                                        <Slider lazyLoad="anticipated"  {...sliderSettings} >
                                            {event.members.map((member, index) => {
                                                return <APRow key={index} style={{ width: '210px' }}>
                                                    <SpeakerDetailsCard title={member.title} name={member.name} designation={member.designation ?? '-'} />
                                                    <APSizedBox width="16px" />
                                                </APRow>
                                            })}
                                        </Slider>
                                    </APExpanded>
                                </APRow>
                            }

                            {/* {
                                event.attatchments && event.attatchments.length > 0 &&
                                <>
                                    <APSizedBox height="16px" />
                                    <APText variant="h3">Attachments</APText>
                                    <APSizedBox height="8px" />
                                    <APRow >
                                        {
                                            event.attatchments.map((attachment, index) => {
                                                return <AttachmentRow key={index} type={attachment.type} label={attachment.label} url={attachment.attatchment.url} />
                                            })
                                        }
                                    </APRow>
                                </>

                            }
                            {
                                event.links && event.links.length > 0 &&
                                <>
                                    <APSizedBox height="8px" />
                                    <APRow style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }} mainAxisSize="min" >
                                        {
                                            event.links.map((link, index) => {
                                                return <LinkRow key={index} title={link.title} link={link.link} />
                                            })
                                        }
                                    </APRow>
                                </>

                            } */}

                        </APColumn>
                    </APExpanded>
                    <APSizedBox width={"24px"} />
                    <APColumn crossAxisAlignment="start" style={{ width: "272px" }}>
                        <APExpanded>
                            <APCard style={{ width: "272px" }}>
                                <APRow crossAxisAlignment="center">
                                    <APIcon icon={event.isOnline ? "video" : "location"} size={16} color={APPalette["brand-300"]} />
                                    <APSizedBox width="4px" />
                                    <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={2}>
                                        {event.isOnline ? "Zoom Event" : event.location}
                                    </APText>
                                </APRow>
                                <APSizedBox height="8px" />
                                <APRow crossAxisAlignment="center">
                                    <APIcon icon={"calendar"} size={16} color={APPalette["brand-300"]} />
                                    <APSizedBox width="4px" />
                                    <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={1}>
                                        {moment(event.eventDate).format('DD MMM YYYY, hh:mm A, ddd')}
                                    </APText>
                                </APRow>
                                <APSizedBox height="8px" />
                                <APRow crossAxisAlignment="center">
                                    <APIcon icon={"language02"} size={16} color={APPalette["brand-300"]} />
                                    <APSizedBox width="4px" />
                                    <APText color={APPalette['grey-500']} variant={'paragraph-small'} maxLines={1}>
                                        {(event.languages)}
                                    </APText>
                                </APRow>
                                <APSizedBox height="8px" />
                                <APRow>
                                    {event.isOnline && event.isLive() ?

                                        <APExpanded>
                                            <APAsyncButton size="small"

                                                onClick={async () => {
                                                    window.open(event.meetingLink);

                                                }}
                                                type="positive"
                                                leading={<APIcon icon={"video"}
                                                    size={16} color={APPalette.white} />}>
                                                Join Now
                                            </APAsyncButton>
                                        </APExpanded> : <APExpanded>
                                            <APButton size="small"
                                                onClick={() => {
                                                    window.open(PARTNER_URL + "/home/resources/events?register=true&event_id=" + event._id);
                                                    // window.open("https://partner.assetplus.in/home/resources/events?register=true&event_id=" + event._id);
                                                }}
                                            >
                                                {"Register"}
                                            </APButton>
                                        </APExpanded>
                                    }

                                    <APSizedBox width="16px" />
                                    <APCard style={{ padding: '12px', borderRadius: '8px', maxHeight: '38px', cursor: 'pointer' }} onClick={async () => {
                                        var link = await getIt(MutualFundApiServices).getDynamicLink({ eventId: location.search.split("event_id=")[1] });
                                        navigator.clipboard.writeText(link);
                                        showAlertSnackBarConfirmation({ content: "Link to the event is copied!", intent: "positive" })
                                    }}>
                                        <APIcon icon={"share02"} size={16} color={APPalette["grey-700"]} />
                                    </APCard>
                                </APRow>
                            </APCard>
                        </APExpanded>


                        {!event.isOnline && event.locationLink != null && <>
                            <APText variant="h3">Venue</APText>
                            <APText variant='paragraph-small'>{event.location}</APText>
                            <APSizedBox height="8px" />
                            <APButton onClick={() => window.open(event.locationLink)}
                                leading={<APIcon icon={'indicator'} size={16} color={APPalette["brand-300"]} />}
                                type='tertiary'>
                                Show on Map
                            </APButton>
                        </>}
                        <APSizedBox height="16px" />
                        <APText variant="h3">Category</APText>
                        <APSizedBox height="8px" />
                        <APTag variant="info" size="small">
                            {formatLabel(event.eventCategory)}
                        </APTag>
                    </APColumn>
                </APRow>
            </APCard>
        </APColumn>


    )

}





function SpeakerDetailsCard({ title, designation, name }: { title: string, name: string, designation: string }) {
    return (
        <APCard style={{ padding: '0px', width: '210px', borderRadius: '8px', height: 110 }}>
            <APColumn crossAxisAlignment="start">
                <APColumn style={{ borderBottom: "1px solid", color: APPalette["grey-200"], }} crossAxisAlignment="start">
                    <APText variant="h5" style={{ padding: '12px' }} maxLines={1}>
                        {title}
                    </APText>
                </APColumn>
                <APSizedBox height="8px" />
                <APColumn
                    crossAxisAlignment="start"
                    style={{ paddingLeft: '12px', paddingBottom: "12px" }}>
                    <APText variant="h4" maxLines={1}>
                        {name}
                    </APText>
                    <APSizedBox height="4px" />
                    <APText variant='paragraph-xsmall' maxLines={2}>
                        {designation}
                    </APText>
                </APColumn>
            </APColumn>
        </APCard>
    )
}


const calculateTimeDifference = (eventDate: Date): { days: number; hours: number; minutes: number; seconds: number } => {
    const now: Date = new Date();
    const difference: number = eventDate.getTime() - now.getTime();

    if (difference <= 0) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds: number = Math.floor(difference / 1000) % 60;
    const minutes: number = Math.floor(difference / (1000 * 60)) % 60;
    const hours: number = Math.floor(difference / (1000 * 60 * 60)) % 24;
    const days: number = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
};

function CountDownCard({ label, value }: { label: string, value: string }) {
    return <APCard style={{ borderRadius: '8px', padding: "8px", width: '60px' }}>
        <APColumn>
            <APText variant='h4'>
                {label}
            </APText>
            <APSizedBox height="4px" />
            <APText variant='paragraph-xsmall'>{value}</APText>
        </APColumn>
    </APCard>
}
const CountdownTimer: React.FC<{ eventDate: Date }> = ({ eventDate }) => {
    const padNumber = (num: number): string => num.toString().padStart(2, '0');
    const [timeDiff, setTimeDiff] = useState(() => calculateTimeDifference(eventDate));
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeDiff(calculateTimeDifference(eventDate));
        }, 1000);
        return () => clearInterval(intervalId);
    }, [eventDate]);

    return (
        <APRow>
            <CountDownCard label={padNumber(timeDiff.days)} value={'Days'} />
            <APSizedBox width="8px" />
            <CountDownCard label={padNumber(timeDiff.hours)} value={'Hrs'} />
            <APSizedBox width="8px" />
            <CountDownCard label={padNumber(timeDiff.minutes)} value={'Mins'} />
            <APSizedBox width="8px" />
            <CountDownCard label={padNumber(timeDiff.seconds)} value={'Sec'} />
        </APRow>
    );
};




function AttachmentRow({ type, label, url }: { type: string, label: string, url: string }) {

    return <APRow mainAxisSize="min"
        onClick={() => {
            window.open(url)
        }}
        style={{ flex: '1 1 calc(50% - 8px)', display: 'flex', alignItems: 'center' }}>

        {
            type == 'image' ? <APImage src={url} height={20} width={20} /> : <APIcon size={24} icon={type === 'pdf' ? 'pdf' : 'ppt'} />
        }
        <APSizedBox width="4px" />
        <APText variant='paragraph-small'>{label}</APText>
    </APRow>
}
function LinkRow({ title, link }: { title: string, link: string }) {
    return (
        <APRow mainAxisSize="min"
            onClick={() =>
                window.open(link)}
            style={{
                flex: '1 1 calc(50% - 8px)',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
            }}
        >
            <APIcon size={16} icon={'link'} color={APPalette["info-300"]} />
            <APSizedBox width="4px" />
            <APText variant='link-medium'>{title}</APText>
            <APSizedBox width="4px" />
            <div onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigator.clipboard.writeText(link);
            }}>
                <APIcon size={16} icon={'copy'} color={APPalette["info-300"]} />
            </div>
        </APRow>
    );
}

export class Banner {
    url!: string
    formats!: {
        small: { url: string },
        large: { url: string },
        thumbnail: { url: string },
    }
}
export class EventModel {
    _id!: string;
    title!: string
    @Type(() => Banner)
    poster!: Banner
    @Type(() => Category)
    createdAt!: Date
    updatedAt!: Date;
    description?: string;
    eventDate!: Date;
    isOnline!: boolean;
    location?: string;
    eventCategory!: string;
    @Type(() => MemberDetailsClass)
    members?: MemberDetailsClass[];
    meetingLink?: string;
    languages?: string;
    city?: string
    locationLink?: string
    @Type(() => Attachment)
    attatchments?: Attachment[]
    @Type(() => LinkClass)
    links?: LinkClass[]
    eventDuration?: string;
    recordedVideoLink?: string;
    isRecent: boolean = false;
    eventCategoryLabel?: string;
    isRegistered: boolean = false;

    isRestrictedEvent?: boolean = false;
    PartnerARNs?: string;

    getEventType() {
        return this.isOnline ? "Webinars" : "Meetups"
    }

    getEventDurationInHours() {
        if (!this.eventDuration) {
            return
        }

        switch (this.eventDuration) {
            case "Hr_1":
                return 1;
            case "Hrs_1_5":
                return 1.5;
            case "Hrs_2":
                return 2;
            case "Hrs_2_5":
                return 2.5;
            case "Hrs_3":
                return 3;
        }
    }

    isLive() {
        const eventDate = new Date(this.eventDate)
        const currentDate = new Date()

        if (!this.getEventDurationInHours() || !this.isOnline) {
            return false
        }

        /// 10 minutes before and until this event ends
        return currentDate.getTime() > eventDate.getTime() - 10 * 60 * 1000 && currentDate.getTime() < eventDate.getTime() + (this.getEventDurationInHours() ?? 0) * 60 * 60 * 1000
    }
}
export class MemberDetailsClass {
    title!: string;
    name!: string;
    designation?: string;
}

class attatchment {
    label!: string;
    type!: string;
    attatchment!: Attatchment;
}
class LinkClass {
    title!: string;
    link!: string;

}

class Attatchment {
    url!: string;
}


export function formatLabel(input: string): string {
    let formatted = input.replace(/_/g, ' ');
    formatted = formatted.replace(/\b\w/g, char => char.toUpperCase());

    let match = formatted.match(/(\d+)\s*(.*)/);
    if (match) {
        let number = match[1];
        let rest = match[2];
        return `${number} Day ${rest}`;
    }

    return formatted;
}
function EmptyState({ label, description, onClick }: { label: string, description?: string, onClick?: () => void }) {
    return <APColumn mainAxisAlignment="center" mainAxisSize="max" style={{ height: "68vh" }}>
        <APImage src={"/img/empty_state.png"} height={120} width={240}></APImage>
        <APText variant='h2'>{label}</APText>
        <APSizedBox height="8px" />
        {description ? <APText>{description}</APText> : undefined}
        <APSizedBox height="8px" />
        {onClick ? <APButton size="small"
            onClick={() => {
                onClick!();
            }}>Explore Events</APButton> : undefined}
    </APColumn>
}


export class Category {
    id!: string
    name!: string
    @Type(() => CategoryIcon)
    icon?: CategoryIcon
}

export class CategoryIcon {
    url?: string
}

export class Attachment {
    label!: string;
    type!: string;
    attatchment!: Attatchment;
}
