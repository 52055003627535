import Axios, { AxiosInstance } from "axios";
import { singleton } from "tsyringe";
import { WebStoryModel } from "../App/WebStory/Models/WebStoryModel";
import { plainToInstance } from "class-transformer";
import { setupCache } from "axios-cache-adapter";
import { StrapiInsuranceTerm } from "insurance-term";
import { EventModel } from "../App/Events";

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
});

@singleton()
export default class CMS2Services {
    instance: AxiosInstance = Axios.create({
        baseURL: "https://strapi.assetplus.in",
    });

    async getWebStoryPosterById(id: string): Promise<WebStoryModel> {
        const result = await this.instance.request({
            method: "GET",
            url: `web-stories/${id}`
        });
        return plainToInstance(WebStoryModel, result.data as {});
    }

    async webStoryShareCount({ _id }: { _id: string }): Promise<void> {
        var response = await this.instance.request({
            method: "PUT",
            url: `/web-stories/${_id}`,
            data: { type: "share" },
        })
        return response.data
    }

    async getEvent(_id: string): Promise<EventModel[]> {
        let result = await this.instance.request({
            method: "GET",
            url: `/events?id=${_id}`,

        });

        return plainToInstance(EventModel, result.data as []);
    }


    async getTermInsurance(): Promise<StrapiInsuranceTerm> {
        let result = await this.instance.request({
            method: "GET",
            url: `/term-insurance`,
            adapter: cache.adapter
        });

        return plainToInstance(StrapiInsuranceTerm, result.data);
    }

}
